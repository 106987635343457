import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'
import utils from './utils'
import VueMoment from 'vue-moment'
import MarketPage from "@/components/page/MarketPage";
import AssetPage from "@/components/page/AssetPage";
import PremiumPage from "@/components/page/PremiumPage";
import RecentPage from "@/components/page/RecentPage";
import MePage from "@/components/page/MePage";
import RegionPage from "@/components/page/RegionPage";
import FacilitiesPage from "@/components/page/FacilitiesPage";
import AssetsPage from "@/components/page/AssetsPage";
import TechnologiesPage from "@/components/page/TechnologiesPage";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.onresize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

if (location.hash.startsWith('#token_type=Bearer&access_token=')) {
  location.hash = '#/?' + location.hash.substr(1);
}

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.prototype.$utils = utils

Vue.use(VueRouter)
Vue.use(VueMoment)

window.$discord = {
  bearerToken: undefined,
  me: undefined,
  guilds: {},
}

window.$discord.signIn = () => {
  location.href = 'https://discord.com/api/oauth2/authorize?client_id=601241235474350122&redirect_uri=https%3A%2F%2Fidlecorp.xyz%2F&response_type=token&scope=identify%20email%20guilds'
}
window.$discord.signOut = () => {
  localStorage.removeItem('discord_bearer_token')
  location.reload()
}
window.$discord.hasBearerToken = () => {
  return typeof window.$discord.bearerToken !== 'undefined' && !window.$discord.bearerToken
}
window.$discord.hasMe = () => {
  return typeof window.$discord.me !== 'undefined'
}
window.$discord.handleSignInResponse = (route) => {
  if (!window.$discord.bearerToken) {
    window.$discord.bearerToken = route.query.access_token || (localStorage.getItem('discord_bearer_token') || false)
  }
}
window.$discord.fetchMe = async () => {
  if (!window.$discord.bearerToken) {
    return false
  }
  else {
    try {
      let result = await axios.get('https://discord.com/api/v8/users/@me', {
        headers: {
          'Authorization': `Bearer ${window.$discord.bearerToken}`
        }
      })
      if (result.status === 200) {
        window.$discord.me = result.data
        localStorage.setItem('discord_bearer_token', window.$discord.bearerToken)
        return result.data
      }
      else {
        window.$discord.bearerToken = false
        localStorage.removeItem('discord_bearer_token')
        return false
      }
    }
    catch (e) {
      window.$discord.bearerToken = false
      localStorage.removeItem('discord_bearer_token')
      return false
    }
  }
}
window.$discord.fetchGuilds = async () => {
  if (!window.$discord.bearerToken) {
    return false
  }
  else {
    try {
      let result = await axios.get('https://discord.com/api/v8/users/@me/guilds', {
        headers: {
          'Authorization': `Bearer ${window.$discord.bearerToken}`
        }
      })
      if (result.status === 200) {
        for (let guild of result.data) {
          window.$discord.guilds[String(guild.id)] = guild
        }
        window.$discord.guilds[-1] = {
          name: 'DM',
          icon: '',
        }
        return window.$discord.guilds
      }
      else {
        return false
      }
    }
    catch (e) {
      return false
    }
  }
}

window.$ic = {}
window.$ic.fetchMe = async () => {
  if (!window.$discord.bearerToken) {
    return false
  }
  else {
    let result = await axios.get('https://ic-hg-service.teemaw.dev/corporation/@me', {
      headers: {
        'Authorization': `Bearer ${window.$discord.bearerToken}`
      }
    })
    if (result.status === 200) {
      window.$ic.me = result.data
      return result.data
    }
    else {
      return false
    }
  }
}
window.$ic.fetchRegions = async () => {
  if (!window.$discord.bearerToken) {
    return false
  }
  else {
    let result = await axios.get('https://ic-hg-service.teemaw.dev/corporation/@me/regions', {
      headers: {
        'Authorization': `Bearer ${window.$discord.bearerToken}`
      }
    })
    if (result.status === 200) {
      window.$ic.regions = result.data
      return result.data
    }
    else {
      return false
    }
  }
}

const routes = [
  { path: '/market', component: MarketPage, alias: '/' },
  { path: '/premium', component: PremiumPage },
  { path: '/recent', component: RecentPage },
  { path: '/me', component: MePage },
  { path: '/asset/:asset_id', component: AssetPage },
  { name: 'region', path: '/region/:region_id', component: RegionPage },
  { path: '/region/:region_id/facilities', component: FacilitiesPage },
  { path: '/region/:region_id/assets', component: AssetsPage },
  { path: '/region/:region_id/technologies', component: TechnologiesPage },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
