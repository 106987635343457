<template>
  <div class="production-coeff panel">
    <span class="name">{{expandName(name)}}</span>
    <span class="value" :class="{'red': value < 1, 'green': value > 1}">
      {{value > 1 ? '+' : ''}}{{$utils.formatPercentage(value-1)}}
    </span>
  </div>
</template>

<script>
export default {
  name: "ProductionCoeff",
  props: ['name', 'value'],
  methods: {
    expandName(oldName) {
      return oldName.replaceAll('_', ' ');
    }
  }
}
</script>

<style scoped>
.production-coeff {

}
.name {
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.3);
}
.value {
  font-size: 2rem;
  width: 100%;
  text-align: right;
}
</style>
