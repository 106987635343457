const ENCODE_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const ENCODE_CHARS_LENGTH = BigInt(ENCODE_CHARS.length);

module.exports = {
    formatCapital(capital) {
        return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatPercentage(decimal) {
        return Number(decimal).toLocaleString("en-US", {style:"percent", minimumFractionDigits: 2});
    },
    formatAmount(amount) {
        return amount.toLocaleString();
    },
    formatTime(time) {
        const pad = (n, z = 2) => ('00' + n).slice(-z);
        let str = pad((time%3.6e6)/6e4 | 0) + ':' + pad((time%6e4)/1000|0);
        let hourStr = pad(time/3.6e6|0);
        if (hourStr !== '00') {
            str = pad(time/3.6e6|0) + ':' + str;
        }
        let msStr = pad(time%1000, 3);
        if (msStr !== '000') {
            str = str + '.' + pad(time%1000, 3);
        }
        return str;
    },
    encodeGuildId(guildId) {
        guildId = BigInt(guildId)
        let code = '';
        if (guildId < 0n) {
            return "DM";
        }
        if (guildId === 602704690219843584n) {
            return "HQ";
        }
        while (guildId > 0) {
            code = ENCODE_CHARS.charAt(Number(guildId % ENCODE_CHARS_LENGTH)) + code;
            guildId = guildId / ENCODE_CHARS_LENGTH;
        }
        return code.substring(0, 3) + "-" + code.substring(3, 6) + "-" + code.substring(6, 9) + "-" + code.substring(9);
    }
};
