<template>
  <div class="panel">
    <span class="name">{{name.toUpperCase()}} <span>FP: {{cost}}</span></span>
    <span>{{stripDescription(description)}}</span>
  </div>
</template>

<script>
export default {
  name: "PolicyDisplay",
  props: ['name', 'description', 'cost'],
  methods: {
    stripDescription(description) {
      return description.replaceAll('*', '').replaceAll('`', '');
    }
  }
}
</script>

<style scoped>
.name {
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
