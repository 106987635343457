<template>
  <div>
    {{regions[guildId].installedTech}}
  </div>
</template>

<script>
export default {
  name: "TechnologiesPage",
  props: ['regions', 'guilds'],
  data() {
    return {
      guildId: this.$route.params.region_id
    };
  },
  watch: {
    $route(to) {
      this.guildId = to.params.region_id;
    }
  }
}
</script>

<style scoped>

</style>
