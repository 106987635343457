<template>
  <div>
    <div class="page">
      <h4>RECENT</h4>
      <div class="offers-grid">
        <offer-row v-for="(offer, i) of recentOrders" :key="i"
                   :itemId="offer.itemId"
                   :name="assetsData[offer.itemId].aliases[0]"
                   :price="offer.price"
                   :filled="offer.amountFilled"
                   :isBuyOffer="offer.isBuyOffer"
                   :timestamp="offer.timestamp"
                   :total="offer.amount" class="offer" @click.native="$router.push('/asset/' + offer.itemId)"></offer-row>
      </div>
    </div>
  </div>
</template>

<script>
import OfferRow from "@/components/market/OfferRow";
export default {
  name: "RecentPage",
  components: {OfferRow},
  props: ['marketData', 'assetsData'],
  data() {
    return {
      recentOrders: []
    }
  },
  methods: {
    updateRecent() {
      this.$http.get('https://ic-hg-service.teemaw.dev/market/recent')
          .then((res) => {
            for (let asset of res.data) {
              this.recentOrders.push(asset);
            }
            this.$forceUpdate();
          });
    }
  },
  created() {
    this.updateRecent();
  }
}
</script>

<style scoped>
.offer {
  cursor: pointer;
}
.offers-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  row-gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .offers-grid {
    grid-template-columns: 1fr;
  }
}
</style>
