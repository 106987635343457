<template>
  <div>
    <div class="outer">

    </div>
  </div>
</template>

<script>
export default {
name: "LoadingBar"
}
</script>

<style scoped>
@keyframes animation {
  0% {
    background-position-x: 100%;
  }
  50% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 100%;
  }
}
.outer {
  border-radius: 4px;
  height: 1rem;
  background-image: linear-gradient(90deg, #222222, #484848, #222222);
  background-position-x: 100%;
  background-size: 200%;
  margin: auto;
  max-width: 22rem;
  animation: animation 4s infinite;
}
@media screen and (max-width: 1200px) {
  .outer {
    max-width: 60vw;
  }
}
</style>
