<template>
  <div>
    <div class="stats-row">
      <span>price</span>
      <span class="stat">{{ formatCapital(marketData.priceHistory[hoveredIndex]) }}</span>
    </div>
    <div class="stats-row">
      <span>max price</span>
      <span class="stat">{{ formatCapital(marketData.priceMaxHistory[hoveredIndex]) }}</span>
    </div>
    <div class="stats-row">
      <span>min price</span>
      <span class="stat">{{ formatCapital(marketData.priceMinHistory[hoveredIndex]) }}</span>
    </div>
    <div class="stats-row">
      <span>volume</span>
      <span class="stat">{{ formatAmount(marketData.volumeHistory[hoveredIndex]) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetHoveredInfo",
  props: ['marketData', 'hoveredIndex'],
  methods: {
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
    formatPercentage(ratio) {
      return (ratio >= 0 ? '+' : '') + ratio.toLocaleString("en-US", {style:"percent"});
    }
  }
}
</script>

<style scoped>

</style>
