<template>
  <div>
    <div class="stats-row">
      <span>spread</span>
      <span class="stat">{{ formatCapital(getBestSellOffer() - getBestBuyOffer()) }}</span>
    </div>
    <div class="stats-row">
      <span>change (24h)</span>
      <span class="stat" :class="{green: get24HourChange() >= 0, red: get24HourChange() < 0}">
        {{ formatPercentage(get24HourChangePercentage()) }} ({{ formatCapital(get24HourChange()) }})
      </span>
    </div>
    <div class="stats-row">
      <span>change (1h)</span>
      <span class="stat" :class="{green: get1HourChange() >= 0, red: get1HourChange() < 0}">
        {{ formatPercentage(get1HourChangePercentage()) }} ({{ formatCapital(get1HourChange()) }})
      </span>
    </div>
    <div class="stats-row">
      <span>volume (24h)</span>
      <span class="stat">{{ formatAmount(get24HourVolume()) }}</span>
    </div>
    <div class="stats-row">
      <span>volume (1h)</span>
      <span class="stat">{{ formatAmount(get1HourVolume()) }}</span>
    </div>
    <div class="stats-row">
      <span>max price (24h)</span>
      <span class="stat">{{ formatCapital(get24HourMaxPrice()) }}</span>
    </div>
    <div class="stats-row">
      <span>min price (24h)</span>
      <span class="stat">{{ formatCapital(get24HourMinPrice()) }}</span>
    </div>
    <div class="stats-row">
      <span>npc value</span>
      <span class="stat">{{ formatCapital(assetData.tag.endsWith('PRODUCT') ? (assetData.defaultValue * 2) : assetData.defaultValue) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetInfo",
  props: ['assetData', 'marketData'],
  data() {
    return {
      buyUnitsOpen: '0',
      sellUnitsOpen: '0',
    }
  },
  methods: {
    get24HourChange() {
      if (this.marketData.priceHistory.length < 24) {
        return false;
      }
      return this.marketData.priceHistory[0] - this.marketData.priceHistory[23];
    },
    get24HourChangePercentage() {
      if (this.marketData.priceHistory.length < 24) {
        return 0;
      }
      return (this.marketData.priceHistory[0] - this.marketData.priceHistory[23]) / this.marketData.priceHistory[23];
    },
    get24HourVolume() {
      let sum = 0;
      for (let volume of this.marketData.volumeHistory) {
        sum += volume;
      }
      return sum;
    },
    get24HourMaxPrice() {
      let maxPrice = 0;
      for (let price of this.marketData.priceMaxHistory) {
        if (price > maxPrice) {
          maxPrice = price;
        }
      }
      return maxPrice;
    },
    get24HourMinPrice() {
      let minPrice = this.marketData.priceMinHistory[0];
      for (let price of this.marketData.priceMinHistory) {
        if (price < minPrice) {
          minPrice = price;
        }
      }
      return minPrice;
    },
    get1HourChange() {
      if (this.marketData.priceHistory.length < 2) {
        return false;
      }
      return this.marketData.priceHistory[0] - this.marketData.priceHistory[1];
    },
    get1HourChangePercentage() {
      if (this.marketData.priceHistory.length < 2) {
        return 0;
      }
      return (this.marketData.priceHistory[0] - this.marketData.priceHistory[1]) / this.marketData.priceHistory[1];
    },
    get1HourVolume() {
      return this.marketData.volumeHistory[0];
    },
    getBestBuyOffer() {
      return Object.keys(this.marketData.buyOffers).sort((a, b) => b - a)[0];
    },
    getBestSellOffer() {
      return Object.keys(this.marketData.sellOffers).sort((a, b) => a - b)[0];
    },
    formatCapital(capital) {
      if (isNaN(capital)) {
        return 'N/A';
      }
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
    formatPercentage(ratio) {
      return (ratio >= 0 ? '+' : '') + ratio.toLocaleString("en-US", {style:"percent"});
    }
  }
}
</script>

<style scoped>

</style>
