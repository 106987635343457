<template>
  <div>
    <div class="page">
      <div class="trending-container">
        <h4>TRENDING</h4>
        <div class="trending-assets-container" v-if="marketDataSorted.length > 0">
          <trending-asset class="trending-asset" v-for="i in new Array(2).keys()"
                          :key="i"
                          :market-data="marketDataSorted[i]"
                          @click.native="showAssetDetails(marketDataSorted[i].itemId)"></trending-asset>
        </div>
        <div class="trending-assets-container" v-if="marketDataSorted.length > 0">
          <trending-asset class="trending-asset" v-for="i in new Array(2).keys()"
                          :key="i"
                          :market-data="marketDataSorted[i+2]"
                          @click.native="showAssetDetails(marketDataSorted[i+2].itemId)"></trending-asset>
        </div>
      </div>
      <div class="other-assets-container">
        <h4>OTHER ASSETS</h4>
        <div class="other-assets" v-if="marketDataSorted.length > 0 && Object.keys(assetsData).length > 0">
          <asset-row class="asset-row asset-header" name="" price="current price" change="change (24h)" style="cursor:default;"></asset-row>
          <asset-row class="asset-row" v-for="i in new Array(marketDataSorted.length - 4).keys()"
                     :key="i"
                     :name="assetsData[marketDataSorted[i+4].itemId].aliases[0]"
                     :price="formatCapital(marketDataSorted[i+4].priceHistory[0])"
                     :change="formatPercentage(get24HourChangePercentage(marketDataSorted[i+4].itemId))"
                     :price-history="marketDataSorted[i+4].priceHistory"
                     @click.native="showAssetDetails(marketDataSorted[i+4].itemId)">
          </asset-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrendingAsset from "@/components/market/TrendingAsset";
import AssetRow from "@/components/market/AssetRow";
export default {
  name: "MarketPage",
  props: ['marketData', 'assetsData'],
  components: {AssetRow, TrendingAsset},
  data() {
    return {
      marketDataSorted: []
    }
  },
  methods: {
    get24HourChange(assetId) {
      if (this.marketData[assetId].priceHistory.length < 24) {
        return '0';
      }
      return this.marketData[assetId].priceHistory[0] - this.marketData[assetId].priceHistory[23];
    },
    get24HourVolume(assetId) {
      let sum = 0;
      for (let volume of this.marketData[assetId].volumeHistory) {
        sum += volume;
      }
      return sum;
    },
    get24HourChangePercentage(assetId) {
      if (this.marketData[assetId].priceHistory.length < 24) {
        return 0;
      }
      return (this.marketData[assetId].priceHistory[0] - this.marketData[assetId].priceHistory[23]) / this.marketData[assetId].priceHistory[23];
    },
    sortMarketData() {
      this.marketDataSorted = Object.values(this.marketData);
      const getNormalizedVolume = (itemId) => {
        let sum = 0;
        for (let i in this.marketData[itemId].volumeHistory) {
          sum += this.marketData[itemId].volumeHistory[i] * (1/((i+0.5)*2));
        }
        return Math.max((sum / 24), this.marketData[itemId].volumeHistory[0]);
      }
      this.marketDataSorted.sort((a, b) => {
        return (getNormalizedVolume(b.itemId) * b.priceHistory[0]) - (getNormalizedVolume(a.itemId) * a.priceHistory[0]);
      });
    },
    showAssetDetails(assetId) {
      this.$router.push(`/asset/${assetId}`);
    },
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
    formatPercentage(ratio) {
      if (isNaN(ratio)) {
        return 'N/A';
      }
      return (ratio >= 0 ? '+' : '') + ratio.toLocaleString("en-US", {style:"percent"});
    },
    waitForMarketData() {
      if (Object.keys(this.marketData).length === 0) {
        setTimeout(this.waitForMarketData, 250);
      }
      else {
        this.sortMarketData();
      }
    }
  },
  watch: {
    marketData() {
      this.$nextTick(this.sortMarketData);
    }
  },
  mounted() {
    this.waitForMarketData();
  }
}
</script>

<style scoped>
.page {
  display: flex;
  justify-content: space-between;
}
.trending-container {
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 1rem;
  position: sticky;
  top: 0;
}
.trending-assets-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: calc(50% - 3rem);
}
.trending-asset {
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 1rem;
}
.trending-asset:last-child {
  margin-right: 0;
}
.other-assets-container {
  width: 22rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 1100px) {
  .page {
    flex-direction: column;
  }
  .trending-container {
    padding-right: 0;
    position: initial;
    height: auto;
  }
  .trending-assets-container {
    flex-direction: column;
  }
  .trending-asset {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .trending-asset:last-child {
    margin-bottom: 0;
  }
  .other-assets-container {
    width: auto;
  }
  .trending-container {
    height: auto!important;
  }
}

@media only screen and (max-height: 850px) {
  .trending-assets-container {
    height: auto;
  }
  .trending-container {
    padding-top: 5rem;
    margin-top: -5rem;
    height: calc(100vh - 5rem);
    top: 0;
    scrollbar-width: none;
  }
  .trending-container::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }
}

.asset-header {
  font-weight: normal;
  color: rgba(255, 255, 255, 0.4);
}
.other-assets {
  font-weight: bold;
}
.asset-row {
  overflow: hidden;
}
.asset-row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.asset-row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
