<template>
  <div class="row">
    <div class="title-row">
      <div class="name-container">
        <img class="icon" :src="facilityData.iconEmoji">
        <span class="name">{{ facilityData.aliases[0] }}</span>
      </div>
      <span>x{{ amount }}</span>
    </div>
    <div>
      XP: {{ $utils.formatAmount(xp||0) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Facility",
  props: ['id', 'amount', 'xp', 'facilityData']
}
</script>

<style scoped>
.row {
  padding: 0.5rem 0;
}
.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name-container {
  display: flex;
  align-items: center;
}
.icon {
  height: 2rem;
  margin-right: 1rem;
}
.name {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
