<template>
  <div>
    <div class="asset-price" v-if="!mini">
      {{ formatCapital(price) }}
      <!--<span class="at-time">@ {{ getTimeDisplay() }}</span>-->
    </div>
    <div :class="{panel: !mini, mini: mini}" @mouseout="() => { if (!isHovered()) {hoveredIndex = 0;} $forceUpdate(); }">
      <svg id="graph-svg" ref="panel" :viewBox="`0 0 ${width} ${height}`" @mousemove="hoverGraph" @pointermove="hoverGraph">
        <line v-for="(line, i) of lines"
              :key="i"
              :x1="line.x1" :y1="line.y1"
              :x2="line.x2" :y2="line.y2"
              :style="`stroke:${color};stroke-width:${strokeWidth}`"
              stroke-linecap="round"/>
        <line :x1="getHoverPointCoords()[0]" :y1="4"
              :x2="getHoverPointCoords()[0]" :y2="height - 4"
              style="stroke:rgba(255,255,255, 0.4);stroke-width:1"
              stroke-linecap="round" v-if="isHovered()"/>
        <circle :cx="getHoverPointCoords()[0]" :cy="getHoverPointCoords()[1]" :r="Number(strokeWidth)+1" stroke="rgb(12,12,12)" :stroke-width="strokeWidth-0.5" :fill="color" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetGraph",
  props: {
    priceHistory: {},
    mini: {
      default: false
    },
    width: {
      default: 500
    },
    height: {
      default: 220
    },
    color: {
      default: 'rgb(255, 255, 255)'
    },
    strokeWidth: {
      default: '2'
    }
  },
  data() {
    return {
      priceMin: false,
      priceMax: false,
      priceDelta: false,
      price: 0,
      hoveredIndex: 0,
      lines: []
    }
  },
  methods: {
    drawLines() {
      let count = 0;
      for (let i = 0; i < this.priceHistory.length - 1; i++) {
        const price = this.priceHistory[i];
        const nextPrice = this.priceHistory[i + 1];
        this.lines.push({
          x1: this.width - (((this.width - 8) / 23) * count) - 4,
          y1: this.height - (((price - this.priceMin) / this.priceDelta + ((this.priceDelta - (this.priceMax - this.priceMin)) / (this.priceDelta * 2))) * (this.height - 8)) - 4,
          x2: this.width - (((this.width - 8) / 23) * (count + 1)) - 4,
          y2: this.height - (((nextPrice - this.priceMin) / this.priceDelta + ((this.priceDelta - (this.priceMax - this.priceMin)) / (this.priceDelta * 2))) * (this.height - 8)) - 4
        });
        count++;
      }
    },
    hoverGraph(event) {
      const hoverRatio = (event.target.clientWidth - event.offsetX) / event.target.clientWidth;
      const hoverIndex = Math.min(Math.round(hoverRatio * 23), 23);
      if (typeof this.priceHistory[hoverIndex] !== 'undefined') {
        this.hoveredIndex = hoverIndex;
        this.price = this.priceHistory[hoverIndex];
        this.$parent.$forceUpdate();
      }
    },
    isHovered() {
      if (!this.$refs.panel) {
        return false;
      }
      return this.$refs.panel.matches(':hover');
    },
    getTimeDisplay() {
      if (this.hoveredIndex === 0) {
        return 'right now';
      }
      else {
        return `${this.hoveredIndex} hour${this.hoveredIndex > 1 ? 's':''} ago`;
      }
    },
    getHoverPointCoords() {
      const x = this.width - (((this.width - 8) / 23) * this.hoveredIndex) - 4;
      const y = this.height - (((this.priceHistory[this.hoveredIndex] - this.priceMin) / this.priceDelta + ((this.priceDelta - (this.priceMax - this.priceMin)) / (this.priceDelta * 2))) * (this.height - 8)) - 4;
      return [x, y];
    },
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
  },
  mounted() {
    let priceMin = Number.MAX_VALUE;
    let priceMax = 0;
    for (const price of this.priceHistory) {
      if (price > priceMax) {
        priceMax = price;
      }
      if (price < priceMin) {
        priceMin = price;
      }
    }
    this.priceMin = priceMin;
    this.priceMax = priceMax;
    this.priceDelta = Math.max(this.priceHistory[0]/2, priceMax - priceMin);
    this.drawLines();
    this.price = this.priceHistory[0];
    this.$parent.$forceUpdate();
  }
}
</script>

<style scoped>
#graph-svg {
  width: 100%;
  touch-action: none;
}
.asset-price {
  font-size: 2.5rem;
  font-family: 'JetBrains Mono', monospace;
  font-weight: bold;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.mini {
  z-index: -2;
}
@media only screen and (max-width: 1200px) {
  .asset-price {
    font-size: 1.6rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .at-time {
    display: block;
  }
}
.at-time {
  font-size: 1rem;
  font-weight: normal;
}
</style>
