<template>
  <div class="menu" :class="active ? 'active' : ''" :style="`top:${top};right:${right};bottom:${bottom};left:${left};`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContextMenu",
  props: ['top', 'bottom', 'right', 'left', 'active']
}
</script>

<style scoped>
.menu {
  position: absolute;
  background-color: #000;
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 100;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.9);
  transition: opacity 0.1s, transform 0.1s;
}
.menu.active {
  opacity: 1;
  pointer-events: initial;
  transform: scale(1);
}
.menu > * > .item {
  padding: 0.5rem;
}
.menu > * > .button {
  cursor: pointer;
}
.menu > * > .header {
  font-weight: bold;
}
</style>
