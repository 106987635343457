<template>
  <div>
    <div class="content">
      <StripeCheckout ref="stripe"
                       :pk="publishableKey"
                       :session-id="sessionId"></StripeCheckout>
      <h4>PREMIUM MEMBERSHIP</h4>
      <h4 v-if="!me" :class="{'shake': shakeNotSignedIn}" style="padding: 0 1rem;">You must be signed in to become a member.</h4>
      <div class="center" v-if="!loadingStripe">
        <span class="panel manage-subscription" v-if="isSubscribed()" @click="redirectToPortal">Manage your {{ getPlanFromProductId(corp.stripeProductId) }} Subscription</span>
      </div>
      <div class="climate" v-if="!loadingStripe">
        <img src="../../../public/stripe_climate.svg" class="climate-icon">
        <span class="green"><b>1%</b> of your subscription will be contributed towards removing CO<sub>2</sub> from our atmosphere.</span>
      </div>
      <!--<div class="annual-switch" v-if="!loadingStripe">
        <span class="panel" :class="{'selected': usd}" @click="usd = true; eur = false;">USD</span>
        <span class="panel" :class="{'selected': eur}" @click="eur = true; usd = false;">EUR</span>
      </div>-->
      <div class="annual-switch" v-if="!loadingStripe">
        <span class="panel" :class="{'selected': monthly}" @click="monthly = true; semiAnnual = false; annual = false;">Monthly</span>
        <span class="panel" :class="{'selected': semiAnnual}" @click="semiAnnual = true; monthly = false; annual = false;">Semi-annual</span>
        <span class="panel" :class="{'selected': annual}" @click="annual = true; semiAnnual = false; monthly = false;">Annual</span>
      </div>
      <div class="plan-container" v-if="!loadingStripe">
        <div class="plan panel" @click="purchase('+');">
          <h1>IdleCorp+</h1>
          <div v-if="usd">
            <span class="price" v-if="monthly">$1.99/mo</span>
            <span class="price" v-if="semiAnnual">$1.49/mo</span>
            <span class="price" v-if="annual">$0.99/mo</span>
            <span class="sub-price" v-if="monthly">or $0.99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for $8.94/6 months</span>
            <span class="sub-price" v-if="annual">for $11.88/yr</span>
          </div>
          <div v-if="eur">
            <span class="price" v-if="monthly">€1,69/mo</span>
            <span class="price" v-if="semiAnnual">€1,26/mo</span>
            <span class="price" v-if="annual">€0,84/mo</span>
            <span class="sub-price" v-if="monthly">or €0,99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for €7,56/6 months</span>
            <span class="sub-price" v-if="annual">for €10,08/yr</span>
          </div>
          <div class="divider"></div>
          <span>Permanent Voter's Boon</span>
          <span>Custom names for retail products</span>
          <span>Access to <span class="stat">$$give</span></span>
          <span>Access to testverse</span>
          <span><b>2x</b> multiplier on vote rewards</span>
          <span>Increased reincorpoation limit to <b>15 tokens</b></span>
          <span>Increased market slots to <b>4</b></span>
          <span>Daily <b>Plus Pass</b> challenges</span>
          <span>Claim <b>1 free token</b> per week</span>
          <div class="divider"></div>
        </div>
        <div class="plan panel" @click="purchase('gold');">
          <h1 class="gold gradient-text">Gold</h1>
          <div v-if="usd">
            <span class="price gold gradient-text" v-if="monthly">$3.99/mo</span>
            <span class="price gold gradient-text" v-if="semiAnnual">$2.99/mo</span>
            <span class="price gold gradient-text" v-if="annual">$1.99/mo</span>
            <span class="sub-price" v-if="monthly">or $1.99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for $17.94/6 months</span>
            <span class="sub-price" v-if="annual">for $23.88/yr</span>
          </div>
          <div v-if="eur">
            <span class="price gold gradient-text" v-if="monthly">€3,29/mo</span>
            <span class="price gold gradient-text" v-if="semiAnnual">€2,46/mo</span>
            <span class="price gold gradient-text" v-if="annual">€1,64/mo</span>
            <span class="sub-price" v-if="monthly">or €1,99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for €14,76/6 months</span>
            <span class="sub-price" v-if="annual">for €19,68/yr</span>
          </div>
          <div class="divider gold gradient"></div>
          <span>Permanent Voter's Boon</span>
          <span>Custom names for retail products</span>
          <span>Access to <span class="stat">$$give</span></span>
          <span>Access to testverse</span>
          <span><b class="gold gradient-text">3x</b> multiplier on vote rewards</span>
          <span>Increased reincorpoation limit to <span class="gold gradient-text nowrap">20 tokens</span></span>
          <span>Increased market slots to <b class="gold gradient-text">6</b></span>
          <span>Daily <span class="gold gradient-text">Gold Pass</span> challenges</span>
          <span>Claim <span class="gold gradient-text">2 free tokens</span> per week</span>
          <div class="divider gold gradient"></div>
        </div>
        <div class="plan panel" @click="purchase('platinum');">
          <h1 class="platinum gradient-text">Platinum</h1>
          <div v-if="usd">
            <span class="price platinum gradient-text" v-if="monthly">$5.99/mo</span>
            <span class="price platinum gradient-text" v-if="semiAnnual">$4.49/mo</span>
            <span class="price platinum gradient-text" v-if="annual">$2.99/mo</span>
            <span class="sub-price" v-if="monthly">or $2.99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for $26.94/6 months</span>
            <span class="sub-price" v-if="annual">for $35.88/yr</span>
          </div>
          <div v-if="eur">
            <span class="price platinum gradient-text" v-if="monthly">€4,99/mo</span>
            <span class="price platinum gradient-text" v-if="semiAnnual">€3,74/mo</span>
            <span class="price platinum gradient-text" v-if="annual">€2,49/mo</span>
            <span class="sub-price" v-if="monthly">or €2,99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for €22,44/6 months</span>
            <span class="sub-price" v-if="annual">for €29,88/yr</span>
          </div>
          <div class="divider platinum gradient"></div>
          <span>Permanent Voter's Boon</span>
          <span>Custom names for retail products</span>
          <span>Access to <span class="stat">$$give</span></span>
          <span>Access to testverse</span>
          <span><b class="platinum gradient-text">4x</b> multiplier on vote rewards</span>
          <span>Increased reincorpoation limit to <span class="platinum gradient-text nowrap">25 tokens</span></span>
          <span>Increased market slots to <b class="platinum gradient-text">8</b></span>
          <span>Daily <span class="platinum gradient-text">Platinum Pass</span> challenges</span>
          <span>Claim <span class="platinum gradient-text">3 free tokens</span> per week</span>
          <div class="divider platinum gradient"></div>
        </div>
        <!--<div class="plan panel" @click="purchase('platinum');">
          <h1 class="diamond gradient-text">Diamond</h1>
          <div v-if="usd">
            <span class="price diamond gradient-text" v-if="monthly">$9.99/mo</span>
            <span class="price diamond gradient-text" v-if="semiAnnual">$7.49/mo</span>
            <span class="price diamond gradient-text" v-if="annual">$4.99/mo</span>
            <span class="sub-price" v-if="monthly">or $4.99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for $26.94/6 months</span>
            <span class="sub-price" v-if="annual">for $35.88/yr</span>
          </div>
          <div v-if="eur">
            <span class="price diamond gradient-text" v-if="monthly">€4,99/mo</span>
            <span class="price diamond gradient-text" v-if="semiAnnual">€3,74/mo</span>
            <span class="price diamond gradient-text" v-if="annual">€2,49/mo</span>
            <span class="sub-price" v-if="monthly">or €2,99/mo annually</span>
            <span class="sub-price" v-if="semiAnnual">for €22,44/6 months</span>
            <span class="sub-price" v-if="annual">for €29,88/yr</span>
          </div>
          <div class="divider diamond gradient"></div>
          <span>Permanent Voter's Boon</span>
          <span>Custom names for retail products</span>
          <span>Access to <span class="stat">$$give</span></span>
          <span>Access to testverse</span>
          <span><b class="diamond gradient-text">4x</b> multiplier on vote rewards</span>
          <span>Increased reincorpoation limit to <span class="diamond gradient-text nowrap">35 tokens</span></span>
          <span>Increased market slots to <b class="diamond gradient-text">10</b></span>
          <span>Daily <span class="diamond gradient-text">Diamond Pass</span> challenges</span>
          <span>Claim <span class="diamond gradient-text">4 free tokens</span> per week</span>
          <div class="divider diamond gradient"></div>
        </div>-->
      </div>
      <div v-if="!loadingStripe">
        <h4>Gratitude Points</h4>
        <p>These bundles have a cooldown period upon purchasing before you can purchase the same bundle again.</p>
        <div class="plan-container">
          <div class="gratitude panel" @click="purchase('grat_1');">
            <h1 class="gratitude-gradient gradient-text">Grat. Points<br/>8x</h1>
            <div v-if="usd">
              <span class="price gratitude-gradient gradient-text">$0.99</span>
            </div>
            <div class="divider gratitude-gradient gradient"></div>
            <div class="gratitude-cooldown-row" v-if="corp">
              <span>2 hour cooldown</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGR9rft00g0OL'] || 0) + (2 * 60 * 60 * 1000) <= Date.now()" class="diamond gradient-text">Available now</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGR9rft00g0OL'] || 0) + (2 * 60 * 60 * 1000) > Date.now()">
                Available {{Number(corp.lastProductPurchaseTime['prod_JGGR9rft00g0OL'] || 0) + (2 * 60 * 60 * 1000) | moment("from")}}
              </span>
            </div>
          </div>
          <div class="gratitude panel" @click="purchase('grat_2');">
            <h1 class="gratitude-gradient gradient-text">Grat. Points<br/>40x + 10x</h1>
            <div v-if="usd">
              <span class="price gratitude-gradient gradient-text">$4.99</span>
            </div>
            <div class="divider gratitude-gradient gradient"></div>
            <div class="gratitude-cooldown-row" v-if="corp">
              <span>6 hour cooldown</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGRgV9FiJgrTd'] || 0) + (6 * 60 * 60 * 1000) <= Date.now()" class="diamond gradient-text">Available now</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGRgV9FiJgrTd'] || 0) + (6 * 60 * 60 * 1000) > Date.now()">
                Available {{Number(corp.lastProductPurchaseTime['prod_JGGRgV9FiJgrTd'] || 0) + (6 * 60 * 60 * 1000) | moment("from")}}
              </span>
            </div>
          </div>
          <div class="gratitude panel" @click="purchase('grat_3');">
            <h1 class="gratitude-gradient gradient-text">Grat. Points<br/>80x + 40x</h1>
            <div v-if="usd">
              <span class="price gratitude-gradient gradient-text">$9.99</span>
            </div>
            <div class="divider gratitude-gradient gradient"></div>
            <div class="gratitude-cooldown-row" v-if="corp">
              <span>24 hour cooldown</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGSGnw4xlsA9v'] || 0) + (24 * 60 * 60 * 1000) <= Date.now()" class="diamond gradient-text">Available now</span>
              <span v-if="Number(corp.lastProductPurchaseTime['prod_JGGSGnw4xlsA9v'] || 0) + (24 * 60 * 60 * 1000) > Date.now()">
                Available {{Number(corp.lastProductPurchaseTime['prod_JGGSGnw4xlsA9v'] || 0) + (24 * 60 * 60 * 1000) | moment("from")}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loadingStripe" class="faqs">
        <h4>FAQs</h4>
        <div class="faqs-grid">
          <div class="panel">
            <h3>How long will I have to wait to receive my membership?</h3>
            <p>Your payment will be linked to the Discord account currently signed in to our website. You will start receiving membership benefits <b>instantly</b> after a successful payment.</p>
          </div>
          <div class="panel">
            <h3>Can I cancel my membership?</h3>
            <p>You will be able to <b>cancel your membership at any time</b> by clicking the "Manage Subscription" button that will appear on this page after becoming a member. If you cancel your membership, you will lose your benefits when your current billing period ends.</p>
          </div>
          <div class="panel">
            <h3>What if I want to change my membership?</h3>
            <p>You will be able to <b>change your membership at any time</b> by clicking the "Manage Subscription" button that will appear on this page after becoming a member. When you change tiers or billing periods, <b>your subscription will be prorated</b>.</p>
          </div>
          <div class="panel">
            <h3>Where does my money go?</h3>
            <p>After <span class="green">1% of your subscription is contributed towards removing CO<sub>2</sub> from our atmosphere</span>, the rest is put towards <b>various hosting costs, as well as supporting me</b>, the sole developer/artist/designer of IdleCorp.</p>
          </div>
        </div>
      </div>
      <div v-if="loadingStripe" class="loader">
        <loading-bar v-if="!error"></loading-bar>
        <div v-if="error"><h3>Something went wrong... Please contact us on Discord.</h3></div>
      </div>
      <!--<div class="plan-container">
        <div class="plan panel">
          <h1 class="diamond gradient-text">IdleCorp Diamond</h1>
          <span class="price diamond gradient-text">$11.99/mo</span>
          <div class="divider diamond gradient"></div>
          <span>Permanent Voter's Boon</span>
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
import { StripeCheckout } from 'vue-stripe-checkout';
import LoadingBar from "@/components/LoadingBar";
export default {
  components: {
    LoadingBar,
    StripeCheckout
  },
  name: "PremiumPage",
  props: ['me'],
  data() {
    return {
      shakeNotSignedIn: false,
      monthly: true,
      semiAnnual: false,
      annual: false,
      usd: true,
      eur: false,
      error: false,
      publishableKey: 'pk_live_51HzTOgDsb3yzrZAqIt0DrkOtcjKgboTYllS4sI7xAaBybE7gKXBEmAd4835MplCbG8JkdgIi7iDc55811XhDEPMl00Yjq6etT6',
      sessionId: '',
      loadingStripe: false,
      corp: window.$ic.me,
      stripeProductMap: {
        'prod_IaesmuZfBQ8e9m': 'IdleCorp+',
        'prod_IaetD9bF2XZxcP': 'IdleCorp Gold',
        'prod_IaeuPciTEX77Y9': 'IdleCorp Platinum'
      }
    }
  },
  methods: {
    getPlanFromProductId(productId) {
      return this.stripeProductMap[productId];
    },
    purchase(plan) {
      if (!window.$discord.hasMe()) {
        localStorage.setItem('purchasePlan', plan);
        window.$discord.signIn();
      }
      if (plan === 'grat_1') {
        priceId = 'price_1IdjuADsb3yzrZAqea0a89Cn';
        if (Number(this.corp.lastProductPurchaseTime['prod_JGGR9rft00g0OL'] || 0) + (24 * 60 * 60 * 1000) > Date.now()) {
          return;
        }
      }
      else if (plan === 'grat_2') {
        priceId = 'price_1IdjueDsb3yzrZAqYH7HYdij';
        if (Number(this.corp.lastProductPurchaseTime['prod_JGGRgV9FiJgrTd'] || 0) + (24 * 60 * 60 * 1000) > Date.now()) {
          return;
        }
      }
      else if (plan === 'grat_3') {
        priceId = 'price_1Idjv7Dsb3yzrZAq0ZMTQkQN';
        if (Number(this.corp.lastProductPurchaseTime['prod_JGGSGnw4xlsA9v'] || 0) + (24 * 60 * 60 * 1000) > Date.now()) {
          return;
        }
      }
      else if (window.$ic.me.stripeProductId.length > 0) {
        this.redirectToPortal();
        return;
      }
      let priceId;
      if (plan === '+') {
        if (this.eur) {
          if (this.annual) {
            priceId = 'price_1I3wkuDsb3yzrZAqInjEh7uA';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3wljDsb3yzrZAqf3X1awOq';
          }
          else {
            priceId = 'price_1I3wkuDsb3yzrZAqInjEh7uA';
          }
        }
        else {
          if (this.annual) {
            priceId = 'price_1HzTZ7Dsb3yzrZAqixbqSUkK';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3whPDsb3yzrZAqRC0vD6ma';
          }
          else {
            priceId = 'price_1HzTZ7Dsb3yzrZAqoWTxaiH7';
          }
        }
      }
      if (plan === 'gold') {
        if (this.eur) {
          if (this.annual) {
            priceId = 'price_1I3wfuDsb3yzrZAqL0NfA0dk';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3wgaDsb3yzrZAqR7TZD3qe';
          }
          else {
            priceId = 'price_1I3wfNDsb3yzrZAqyGPjbN2a';
          }
        }
        else {
          if (this.annual) {
            priceId = 'price_1HzTZnDsb3yzrZAqKU9zAZVz';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3webDsb3yzrZAqFqnLuOJe';
          }
          else {
            priceId = 'price_1HzTZnDsb3yzrZAqxFfsiNMZ';
          }
        }
      }
      if (plan === 'platinum') {
        if (this.eur) {
          if (this.annual) {
            priceId = 'price_1I3waeDsb3yzrZAqQvbFOBiC';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3wdRDsb3yzrZAqjZrIhaOb';
          }
          else {
            priceId = 'price_1I3waGDsb3yzrZAqdpvPUwfr';
          }
        }
        else {
          if (this.annual) {
            priceId = 'price_1HzTaKDsb3yzrZAqe7AaGF61';
          }
          else if (this.semiAnnual) {
            priceId = 'price_1I3RB5Dsb3yzrZAqPlfPAQjl';
          }
          else {
            priceId = 'price_1HzTaKDsb3yzrZAqdxs5fWil';
          }
        }
      }
      this.loadingStripe = true;
      this.createCheckoutSession(priceId).then((data) => {
        // Call Stripe.js method to redirect to the new Checkout page
        this.sessionId = data.data.sessionId;
        this.$nextTick(() => {
          this.$refs.stripe.redirectToCheckout();
        });
        this.$forceUpdate();
      }).catch(() => {
        this.error = true;
      });
    },
    isSubscribed() {
      if (!window.$discord.bearerToken) {
        return false;
      }
      return window.$ic.me.stripeProductId.length > 0;
    },
    redirectToPortal() {
      this.loadingStripe = true;
      this.createPortalSession().then((res) => {
        location.href = res.data.url;
      });
    },
    async createPortalSession() {
      return await this.$http.get(`https://ic-hg-service.teemaw.dev/customer-portal/${window.$discord.bearerToken}`);
    },
    async createCheckoutSession(priceId) {
      return await this.$http.get(`https://ic-hg-service.teemaw.dev/create-checkout-session/${window.$discord.bearerToken}/${priceId}`);
    }
  },
  created() {
    let purchasePlan = localStorage.getItem('purchasePlan');
    if (purchasePlan !== null) {
      localStorage.removeItem('purchasePlan');
      if (this.isSubscribed()) {
        this.redirectToPortal();
      }
      else if (window.$discord.hasMe()) {
        this.purchase(purchasePlan);
      }
    }
  }
}
</script>

<style scoped>
.content {
  padding: 0.5rem;
}
@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 1rem;
  }
  50% {
    margin-left: -1rem;
  }
  75% {
    margin-left: 1rem;
  }
  100% {
    margin-left: 0;
  }
}
.shake {
  animation: shake 0.4s forwards;
}
.manage-subscription {
  width: 14rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
}
.gratitude, .plan {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0.5rem;
  cursor: pointer;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.75);
}
.plan {
  min-height: 31rem;
}
.plan > span > b, .gratitude > span > b {
  color: rgba(255, 255, 255, 1);
}
.plan > h1, .gratitude > h1 {
  margin-top: 0;
  text-align: center;
}

.gratitude-cooldown-row {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  .plan-container {
    flex-direction: column-reverse;
  }
}
.gold {
  background-image: linear-gradient(45deg, #5F3E26, #f3ec78, #5F3E26);
}
.platinum {
  background-image: linear-gradient(45deg, #323c5f, #B2D5F3, #323C5F);
}
.diamond {
  /*background-image: linear-gradient(45deg, #96c5de, #fff2f2, #96c5de);*/
  background-image: linear-gradient(45deg, #96c5de, #a6e0cc, #eed4d4, #a6e0cc, #96c5de);
}
.gratitude-gradient {
  background-image: linear-gradient(45deg, #30643c, #74dab8, #30643c);
}
.gratitude-unavailable-gradient {
  background-image: linear-gradient(45deg, #643030, #da7474, #643030);
}
.plan:hover > * .gradient-text, .plan:hover > * .gradient,
.plan:hover > .gradient-text, .plan:hover > .gradient,
.gratitude:hover > * .gradient-text, .gratitude:hover > * .gradient,
.gratitude:hover > .gradient-text, .gratitude:hover > .gradient{
  background-position-x: 0;
}
.nowrap {
  white-space: nowrap;
}
.gradient-text {
  background-position-x: 100%;
  background-size: 200%;
  background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  transition: background-position 0.4s;
}
.gradient {
  background-position-x: 100%;
  background-size: 200%;
  transition: background-position 0.4s;
}
.price {
  text-align: center;
  font-size: 1.4rem;
  display: block;
}
.sub-price {
  text-align: center;
  display: block;
}
.divider {
  margin: 1rem 0 1rem 0;
  height: 2px;
  width: 100%;
  background-color: #fff;
}
.annual-switch {
  margin: 0.5rem auto 0.5rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.annual-switch > span {
  text-align: center;
  cursor: pointer;
}
.annual-switch > .selected {
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.annual-switch > span {
  border-radius: 0;
}
.annual-switch > span:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.annual-switch > span:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.climate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
.climate-icon {
  height: 1.5rem;
  margin-right: 0.5rem;
}
.loader {
  height: calc(100vh - 10rem);
  display: flex;
  align-items: center;
}
.loader > div {
  width: 100%;
}
.faqs {
  max-width: 1300px;
  margin: auto;
  padding: 0.5rem;
}
.faqs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  row-gap: 1rem;
}
.panel > h3 {
  margin-top: 0;
}
.panel > p {
  margin-bottom: 0;
}

@media screen and (max-width: 1100px) {
  .faqs-grid {
    grid-template-columns: 1fr;
  }
}
</style>
