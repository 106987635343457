<template>
  <div>
    <div class="page">
      <h1>{{guilds[guildId].name}}</h1>
      <h4>STATS</h4>
      <div class="region-stats-container">
        <region-stat name="HAPPINESS" :value="regions[guildId].region.happiness"></region-stat>
        <region-stat name="TOTAL LAND" :value="$utils.formatAmount(regions[guildId].region.landCount)"></region-stat>
        <region-stat name="POPULATION" :value="$utils.formatAmount(regions[guildId].region.population)"></region-stat>
      </div>
      <h4>PRODUCTION</h4>
      <div class="production-coeff-container">
        <div v-for="(productionCoeff, productionType) in regions[guildId].region.productionCoeffs" :key="productionType">
          <production-coeff :name="productionType" :value="productionCoeff"></production-coeff>
        </div>
      </div>
      <h4>POLICIES ({{getUsedFundingPoints()}}/{{getTotalFundingPoints()}} FP)</h4>
      <div class="policies-container">
        <div v-for="policy in regions[guildId].region.activePolicies" :key="policy.id">
          <policy-display :name="policy.aliases[0]" :description="policy.description" :cost="policy.cost"></policy-display>
        </div>
      </div>
      <h4>SERVICES</h4>
      <div class="services-container">
        <div v-for="(progress, service) in regions[guildId].region.servicesProgress" :key="service">
          <service-display :service="servicesData[service]" :progress="progress"></service-display>
        </div>
      </div>
      <!--{{regions[guildId].region.servicesProgress}}
      {{regions[guildId].region}}-->
    </div>
  </div>
</template>

<script>
import ProductionCoeff from "@/components/region/ProductionCoeff";
import RegionStat from "@/components/region/RegionStat";
import PolicyDisplay from "@/components/region/PolicyDisplay";
import ServiceDisplay from "@/components/region/ServiceDisplay";
export default {
  name: "RegionPage",
  components: {ServiceDisplay, PolicyDisplay, RegionStat, ProductionCoeff},
  props: ['regions', 'guilds', 'servicesData'],
  data() {
    return {
      guildId: this.$route.params.region_id
    };
  },
  methods: {
    getUsedFundingPoints() {
      let usedFundingPoints = 0;
      for (let policy of this.regions[this.guildId].region.activePolicies) {
        usedFundingPoints += policy.cost;
      }
      return usedFundingPoints;
    },
    getTotalFundingPoints() {
      return 15;
    }
  },
  watch: {
    $route(to) {
      this.guildId = to.params.region_id;
    }
  }
}
</script>

<style scoped>
.region-stats-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.production-coeff-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
.policies-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.page {
  padding-bottom: 2rem;
}
@media only screen and (max-width: 1300px) {
  .region-stats-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .production-coeff-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .policies-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .services-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
