<template>
  <div>
    <div class="page">
      <h1>{{ getMe().name }}</h1>
      <span class="premium type dev" v-if="corporation.userId === '133379387142701056'">DEVELOPER</span>
      <span class="premium type mod" v-if="false">MODERATOR</span>
      <span class="premium type platinum" v-if="corporation.stripeProductId === 'prod_IaeuPciTEX77Y9'">PLATINUM</span>
      <span class="premium type gold" v-if="corporation.stripeProductId === 'prod_IaetD9bF2XZxcP'">GOLD</span>
      <span class="premium type plus" v-if="corporation.stripeProductId === 'prod_IaesmuZfBQ8e9m'">IDLECORP+</span>
      <h2 v-if="getMe().description">{{ corporation.description }}</h2>
      <div class="type motto" v-if="!corporation.description">NO MOTTO</div>
      <div class="currency-columns">
        <div class="panel">
          <span class="type">CAPITAL</span>
          <span class="big stat">{{ $utils.formatCapital(corporation.capital) }}</span>
        </div>
        <div class="panel">
          <span class="type">TOKENS</span>
          <span class="big stat">{{ $utils.formatAmount(corporation.riTokens) }}</span>
          <span class="minor">SCORE: {{ $utils.formatAmount(corporation.riScore) }}</span>
        </div>
        <div class="panel">
          <span class="type">GRATITUDE POINTS</span>
          <span class="big stat">{{ $utils.formatAmount(corporation.votePoints) }}</span>
          <span class="minor">SCORE: {{ $utils.formatAmount(corporation.voteScore) }}</span>
        </div>
      </div>
      <div class="timer-columns">
        <div class="panel">
          <span class="type">NEXT WEEKLY REWARD</span>
          <span class="big stat" v-if="getHasClaimedWeekly(corporation.lastWeekly)">
            {{ getNextWeeklyTimestamp() | moment("from") }}
          </span>
          <span class="big stat" v-if="!getHasClaimedWeekly(corporation.lastWeekly)">
            ready now
          </span>
        </div>
        <div class="panel">
          <span class="type">NEXT REINCORPORATION</span>
          <span class="big stat" v-if="Number(corporation.lastReincorporation) + (24 * 60 * 60 * 1000) > Date.now()">
            {{ Number(corporation.lastReincorporation) + (24 * 60 * 60 * 1000) | moment("from") }}
          </span>
          <span class="big stat" v-if="Number(corporation.lastReincorporation) + (24 * 60 * 60 * 1000) <= Date.now()">
            ready now
          </span>
        </div>
        <div class="panel">
          <span class="type">NEXT VOTE</span>
          <span class="big stat" v-if="Number(corporation.lastVoteClaimed) + (12 * 60 * 60 * 1000) > Date.now()">
            {{ Number(corporation.lastVoteClaimed) + (12 * 60 * 60 * 1000) | moment("from") }}
          </span>
          <span class="big stat" v-if="Number(corporation.lastVoteClaimed) + (12 * 60 * 60 * 1000) <= Date.now()">
            ready now
          </span>
        </div>
      </div>
      <h4>REGIONS</h4>
      <div class="region panel" v-for="region in sortRegions(Object.values(regions))" :key="region.region.guildId">
        <div class="region-header">
          <img class="region-icon" v-if="region.guildId !== '-1'" :src="`https://cdn.discordapp.com/icons/${region.guildId}/${getGuild(region.guildId).icon}.png`">
          <span class="region-name" v-if="region.guildId !== '-1'">{{ getGuild(region.guildId).name }}</span>
        </div>
        <span class="region-code">Region Code: <span class="stat">{{ $utils.encodeGuildId(region.guildId) }}</span></span>
        <span class="type">FACILITIES ({{getFacilitiesCount(region)}}/{{region.assets['land']}})</span>
        <div class="facilities-container">
          <Facility v-for="facility in Object.keys(region.facilities)"
                    :key="facility"
                    :id="facility"
                    :facility-data="facilitiesData[facility]"
                    :xp="region.facilityXpMap[facility]"
                    :amount="region.facilities[facility]">

          </Facility>
        </div>
        <span class="type">ASSETS</span>
        <div class="assets-container">
          <Asset v-for="asset in Object.keys(region.assets)" :key="asset"
                 :id="asset"
                 :asset-data="assetsData[asset]"
                 :amount="region.assets[asset]"
                 :locked="region.lockedAssets.indexOf(asset) !== -1"></Asset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Facility from "@/components/me/Facility";
import Asset from "@/components/me/Asset";
export default {
  name: "MePage",
  props: ['facilitiesData', 'assetsData'],
  components: {Facility, Asset},
  data() {
    return {
      corporation: {},
      regions: {},
    }
  },
  methods: {
    getMe() {
      return window.$ic.me;
    },
    getGuild(guildId) {
      return window.$discord.guilds[String(guildId)];
    },
    getFacilitiesCount(region) {
      let count = 0;
      for (let regionalCount of Object.values(region.facilities)) {
        count += regionalCount;
      }
      return count;
    },
    getHasClaimedWeekly(timestamp) {
      let week = Math.floor(Number(timestamp) / (7 * 24 * 60 * 60 * 1000));
      let currentWeek = Math.floor(Date.now() / (7 * 24 * 60 * 60 * 1000));
      return week === currentWeek;
    },
    getNextWeeklyTimestamp() {
      let currentWeek = Math.floor(Date.now() / (7 * 24 * 60 * 60 * 1000));
      return (currentWeek + 1) * (7 * 24 * 60 * 60 * 1000);
    },
    sortRegions(regions) {
      return regions.sort((a, b) => {
        return this.getFacilitiesCount(b) - this.getFacilitiesCount(a);
      });
    }
  },
  created() {
    this.corporation = this.getMe();
    window.$ic.fetchRegions()
    .then((regions) => {
      this.regions = regions;
    });
  }
}
</script>

<style scoped>
.currency-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
}
.timer-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
}
.premium.type.plus {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);
}
.premium.type.gold {
  background-image: linear-gradient(45deg, #f3ec78, #5F3E26);
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
}
.premium.type.platinum {
  background-image: linear-gradient(45deg, #B2D5F3, #323C5F);
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
}
.premium.type.dev {
  background-image: linear-gradient(45deg, #fff2f2, #a6e0cc, #96c5de);
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
}
.premium.type.mod {
  background-image: linear-gradient(45deg, #8e63ff, #2a256b);
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
}
.premium.type {
  border-radius: 4px;
  padding: 0.1rem 0.25rem;
  margin-right: 1rem;
}
.type {
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.3);
}
.type.motto {
  padding: 1rem 0;
  font-weight: bold;
}
.big.stat {
  text-align: right;
  font-size: 2rem;
  font-weight: bold;
}
.big.stat span {
  font-size: 2rem;
}
.minor {
  text-align: right;
}
.region {
  margin-bottom: 1rem;
}
.region-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.region-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
}
.region-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.region-code {
  margin-bottom: 0.5rem;
}
.facilities-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
}
.assets-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
}
@media only screen and (max-width: 1300px) {
  .currency-columns {
    grid-template-columns: repeat(1, 1fr);
  }
  .timer-columns {
    grid-template-columns: repeat(1, 1fr);
  }
  .facilities-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .assets-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
