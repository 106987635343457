<template>
  <div class="asset">
    <div class="row">
      <span class="name-container">
        <img class="icon" :src="assetData.emojiUrl">
        <span class="name">{{ assetData.aliases[0] }}</span>
      </span>
      <span>
        x{{ $utils.formatAmount(Number(amount)) }}
        <span v-if="locked">
          &#x1F512;
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Asset",
  props: ['name', 'amount', 'locked', 'assetData']
}
</script>

<style scoped>
.asset {
  padding: 0.5rem 0;
}
.name-container {
  display: flex;
  align-items: center;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon {
  height: 2rem;
  margin-right: 1rem;
}
.name {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
