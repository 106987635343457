<template>
  <div class="offer panel">
    <div class="row">
      <div>
        <img :src="$parent.assetsData[itemId].emojiUrl">
        <div class="name">{{ name }}</div>
        <time-ago :datetime="timestamp" long></time-ago>
      </div>
      <div class="price">
        <span>
          <span>{{ isBuyOffer ? 'BIDDING ' : 'ASKING ' }}</span>
          <span class="stat">{{ $utils.formatCapital(price) }}</span>
        </span>

        <div class="stat">
          <div>{{ $utils.formatAmount(filled) }}/{{ $utils.formatAmount(total) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeAgo from 'vue2-timeago'
export default {
  name: "OfferRow",
  components: {TimeAgo},
  props: ['name', 'price', 'filled', 'total', 'itemId', 'isBuyOffer', 'timestamp']
}
</script>

<style scoped>
.offer {
  margin-bottom: 1rem;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row > div {
  flex-grow: 1;
  flex-basis: 0;
}
.row > div:last-child {
  text-align: right;
}
.price {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name {
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (max-width: 1100px) {
  .row {
    flex-direction: column;
  }
  .row > div:last-child {
    text-align: left;
  }
  .price {
    font-size: 1.15rem;
  }
  .offer {
    min-height: 169px;
  }
}
</style>