<template>
  <div>
    <div class="panel">
      <div class="asset-name" v-if="$parent.assetsData[marketData.itemId]">
        <img :src="$parent.assetsData[marketData.itemId].emojiUrl">
        <div>{{ $parent.assetsData[marketData.itemId].aliases[0] }}</div>
        <span class="type">{{ $parent.assetsData[marketData.itemId].tag }}</span>
      </div>
      <asset-graph id="graph" ref="graph" :price-history="marketData.priceHistory" mini="true" height="80" stroke-width="3"></asset-graph>
      <div class="stats-container">
        <div class="asset-price stat">{{ formatCapital(marketData.priceHistory[getHoveredIndex()]) }}</div>
        <!--<div class="stats-row">
          <span>change (24h)</span>
          <span class="stat" :class="{green: get24HourChange() >= 0, red: get24HourChange() < 0}">
            {{ formatPercentage(get24HourChangePercentage()) }} ({{ formatCapital(get24HourChange()) }})
          </span>
        </div>-->
        <div class="stats-row">
          <span>change (1h)</span>
          <span class="stat" :class="{green: get1HourChange() >= 0, red: get1HourChange() < 0}">
            {{ formatPercentage(get1HourChangePercentage()) }} ({{ formatCapital(get1HourChange()) }})
          </span>
        </div>
        <!--<div class="stats-row">
          <span>volume (24h)</span>
          <span class="stat">{{ formatAmount(get24HourVolume()) }}</span>
        </div>-->
        <div class="stats-row">
          <span>volume (1h)</span>
          <span class="stat">{{ formatAmount(get1HourVolume()) }}</span>
        </div>
        <div class="stats-row">
          <span>asks</span>
          <span class="stat">{{ formatAmount(buyUnitsOpen) }}</span>
        </div>
        <div class="stats-row">
          <span>bids</span>
          <span class="stat">{{ formatAmount(sellUnitsOpen) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetGraph from "@/components/market/AssetGraph";
export default {
  name: "TrendingAsset",
  components: {AssetGraph},
  props: ['marketData'],
  data() {
    return {
      buyUnitsOpen: '0',
      sellUnitsOpen: '0',
    }
  },
  methods: {
    get24HourChange() {
      if (this.marketData.priceHistory.length < 24) {
        return false;
      }
      return this.marketData.priceHistory[0] - this.marketData.priceHistory[23];
    },
    get24HourChangePercentage() {
      if (this.marketData.priceHistory.length < 24) {
        return 0;
      }
      return (this.marketData.priceHistory[0] - this.marketData.priceHistory[23]) / this.marketData.priceHistory[23];
    },
    get24HourVolume() {
      let sum = 0;
      for (let volume of this.marketData.volumeHistory) {
        sum += volume;
      }
      return sum;
    },
    get1HourChange() {
      if (this.marketData.priceHistory.length < 2) {
        return false;
      }
      return this.marketData.priceHistory[0] - this.marketData.priceHistory[1];
    },
    get1HourChangePercentage() {
      if (this.marketData.priceHistory.length < 2) {
        return 0;
      }
      return (this.marketData.priceHistory[0] - this.marketData.priceHistory[1]) / this.marketData.priceHistory[1];
    },
    get1HourVolume() {
      return this.marketData.volumeHistory[0];
    },
    getHoveredIndex() {
      return this.$refs.graph ? this.$refs.graph.hoveredIndex : 0;
    },
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
    formatPercentage(ratio) {
      return (ratio >= 0 ? '+' : '') + ratio.toLocaleString("en-US", {style:"percent"});
    }
  },
  mounted() {
    this.$http.get(`https://ic-hg-service.teemaw.dev/market/asset/${this.marketData.itemId}`)
      .then((res) => {
        this.buyUnitsOpen = 0;
        for (let offerSize of Object.values(res.data.buyOffers)) {
          this.buyUnitsOpen += offerSize;
        }
        this.sellUnitsOpen = 0;
        for (let offerSize of Object.values(res.data.sellOffers)) {
          this.sellUnitsOpen += offerSize;
        }
      });
  }
}
</script>

<style scoped>
.panel {
  cursor: pointer;
  position: relative;
}
#graph {
  top: 0;
}
.asset-name {
  font-weight: bold;
  font-size: 1.5rem;
}
.asset-price {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0 0;
  text-align: right;
}
.type {
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.3);
}
@media only screen and (max-width: 1200px) {
  .asset-name {
    font-size: 1.3rem;
  }
  .asset-price {
    font-size: 1.4em;
  }
}
</style>
