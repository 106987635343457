<template>
  <div>
    <h4>ORDERBOOK</h4>
    <div id="order-book-container">
      <div class="side">
        <div class="panel" id="bids-panel">
          <div class="stats-row" v-for="(amount, i) of buyTranches" :key="i">
            <div class="book-bar" :style="`width: ${formatPercentage(amount/maxAmount)};`"></div>
            <span v-if="i < 5">&gt; {{ formatCapital(middle - (scale * i)) }}</span>
            <span v-if="i === 5">&lt; {{ formatCapital(middle - (scale * (i - 1))) }}</span>
            <span class="stat">{{ formatAmount(amount) }}</span>
          </div>
        </div>
      </div>
      <div class="side">
        <div class="panel">
          <div class="stats-row" v-for="(amount, i) of sellTranches" :key="i">
            <div class="book-bar" :style="`width: ${formatPercentage(amount/maxAmount)};`"></div>
            <span class="stat">{{ formatAmount(amount) }}</span>
            <span v-if="i < 5">&lt; {{ formatCapital(middle + (scale * (i + 1))) }}</span>
            <span v-if="i === 5">&gt; {{ formatCapital(middle + (scale * (i))) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderBook",
  props: ['marketData'],
  data() {
    const scale = Math.pow(10, Math.max((Math.log(this.marketData.priceHistory[0]) * Math.LOG10E + 1 | 0) - 2, 0));
    let middlePrice = this.marketData.priceHistory[0];
    middlePrice = Math.min(middlePrice, Object.keys(this.marketData.sellOffers).sort()[0] || Number.MAX_VALUE);
    middlePrice = Math.max(middlePrice, Object.keys(this.marketData.buyOffers).sort().lastItem || 0);
    const middle = Math.floor(middlePrice / scale) * scale;
    let buyTranches = new Array(6).fill(0);
    let maxAmount = 1;
    for (let [price, amount] of Object.entries(this.marketData.buyOffers)) {
      const index = Math.max(0, Math.min(Math.floor((middle - price) / scale), 5));
      buyTranches[index] += amount;
      if (buyTranches[index] > maxAmount) {
        maxAmount = buyTranches[index];
      }
    }
    let sellTranches = new Array(6).fill(0);
    for (let [price, amount] of Object.entries(this.marketData.sellOffers)) {
      const index = Math.max(0, Math.min(Math.floor((price - middle) / scale), 5));
      sellTranches[index] += amount;
      if (sellTranches[index] > maxAmount) {
        maxAmount = sellTranches[index];
      }
    }
    return {
      scale,
      middle,
      buyTranches,
      sellTranches,
      maxAmount
    }
  },
  methods: {
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
    formatPercentage(ratio) {
      return (ratio >= 0 ? '+' : '') + ratio.toLocaleString("en-US", {style:"percent"});
    }
  }
}
</script>

<style scoped>
#order-book-container {
  display: flex;
}
.panel:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.side {
  flex-grow: 1;
  flex-basis: 0;
}
.side:first-child>.panel {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}
.side:last-child>.panel {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.panel {
  padding: 0;
  height: auto;
  overflow: hidden;
}
.stats-row {
  position: relative;
  width: calc(100% - 2rem);
  padding: 0.5rem 1rem;
  transition: background-color 0.1s;
}
.stats-row:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.book-bar {
  position: absolute;
  height: 100%;
  width: 50%;
  margin-top: -0.5rem;
  background-color: rgba(255, 255, 255, 0.15);
}
.side:first-child>.panel>.stats-row>.book-bar {
  right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: rgba(120, 255, 120, 0.15);
}
.side:last-child>.panel>.stats-row>.book-bar {
  left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: rgba(255, 120, 120, 0.15);
}
@media only screen and (max-width: 1100px) {
  #order-book-container {
    flex-direction: column;
  }
  .side:first-child>.panel {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
  .side:last-child>.panel {
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .side:last-child>.panel>.stats-row {
    flex-direction: row-reverse;
  }
  .side:first-child>.panel>.stats-row>.book-bar {
    right: unset;
    left: 0;
  }
  .side>.panel>.stats-row>.book-bar {
    border-radius: 0 4px 4px 0!important;
  }
  #bids-panel {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
