<template>
  <div id="app">
    <LeftNav v-if="isDataLoaded()"
             :me="me"
             :corporation="corporation"
             :regions="regions"
             :guilds="guilds"
             :open-mobile-menu="openMobileMenu"
             @click="openMobileMenu = false;"></LeftNav>
    <div class="loading-container" v-if="!isDataLoaded()">
      <img src="./assets/icon_transparent.png" style="margin-bottom: 2rem;">
      <loading-bar></loading-bar>
    </div>
    <div class="container">
      <div class="close-mobile-menu" v-if="openMobileMenu" @click.prevent="openMobileMenu = false;"></div>
      <div class="mobile-top-bar" v-if="isDataLoaded()">
        <span class="menu-button" @click="openMobileMenu = true;">&#x2630;</span>
      </div>
      <router-view :market-data="marketData"
                   :assets-data="assetsData"
                   :facilities-data="facilitiesData"
                   :services-data="servicesData"
                   :policies-data="policiesData"
                   :me="me"
                   :guilds="guilds"
                   :regions="regions"
                   v-if="isDataLoaded()">
      </router-view>
    </div>
  </div>
</template>

<script>
import LeftNav from "@/components/LeftNav";
import LoadingBar from "@/components/LoadingBar";
export default {
  name: 'App',
  components: {LoadingBar, LeftNav},
  data() {
    return {
      assetsData: {},
      facilitiesData: {},
      marketData: {},
      servicesData: {},
      policiesData: {},
      me: undefined,
      corporation: undefined,
      guilds: undefined,
      regions: undefined,
      openMobileMenu: false,
    };
  },
  methods: {
    isDataLoaded() {
      return Object.keys(this.marketData).length &&
      Object.keys(this.assetsData).length &&
      Object.keys(this.facilitiesData).length &&
      this.isNotSignedIn() ? true : (this.corporation && this.guilds && this.regions);
    },
    updateData() {
      this.$http.get('https://ic-hg-service.teemaw.dev/data/assets')
          .then((res) => {
            for (let asset of res.data) {
              if (asset.id.startsWith('tech_')) {
                asset.tag = 'TECHNOLOGY';
              }
              else if (asset.type) {
                asset.tag = asset.type + ' PRODUCT';
              }
              else {
                asset.tag = 'ASSET';
              }
              asset.emojiUrl = `https://cdn.discordapp.com/emojis/${asset.emojiStr.split(':')[2].substr(0, 18)}.png`;
              asset.description = asset.description.replaceAll('`', '');
              asset.description = asset.description.replaceAll('*', '');
              this.assetsData[asset.id] = asset;
            }
            this.$forceUpdate();
          });
      this.$http.get('https://ic-hg-service.teemaw.dev/data/facilities')
          .then((res) => {
            for (let facility of res.data) {
              facility.iconEmoji = `https://cdn.discordapp.com/emojis/${facility.iconEmoji.split(':')[2].substr(0, 18)}.png`;
              facility.description = facility.description.replaceAll('`', '');
              facility.description = facility.description.replaceAll('*', '');
              this.facilitiesData[facility.id] = facility;
            }
            this.$forceUpdate();
          });
      this.$http.get('https://ic-hg-service.teemaw.dev/data/services')
          .then((res) => {
            for (let service of Object.values(res.data)) {
              this.servicesData[service.id] = service;
            }
            this.$forceUpdate();
          });
      this.$http.get('https://ic-hg-service.teemaw.dev/data/policies')
          .then((res) => {
            for (let policy of Object.values(res.data)) {
              policy.description = policy.description.replaceAll('`', '');
              policy.description = policy.description.replaceAll('*', '');
              this.policiesData[policy.id] = policy;
            }
            this.$forceUpdate();
          });
      this.$http.get('https://ic-hg-service.teemaw.dev/market')
          .then((res) => {
            for (let asset of res.data) {
              this.marketData[asset.itemId] = asset;
            }
            this.$forceUpdate();
          });
    },
    isNotSignedIn() {
      return window.$discord.bearerToken === false;
    }
  },
  created() {
    window.$discord.handleSignInResponse(this.$route);
    window.$discord.fetchMe().then((res) => {
      this.me = res;
      window.$ic.fetchMe().then((res) => {
        this.corporation = res;
        let purchasePlan = localStorage.getItem('purchasePlan');
        if (purchasePlan !== null) {
          this.$router.push('/premium');
        }
      });
      window.$ic.fetchRegions().then((res) => {
        this.regions = res;
      });
    });
    window.$discord.fetchGuilds().then((res) => {
      this.guilds = res;
    });
    this.updateData();
    setInterval(this.updateData, 60 * 1000);
  }
}
</script>

<style>
html {
  background-color: #141414;
}
#app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
body {
  margin: 0;
}
a {
  color: #9aadb7!important;
  font-size: 1.5rem;
  font-weight: bold;
}
.mobile-top-bar {
  display: none;
}
.close-mobile-menu {
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 100%;
}
.container {
  margin-top: -100vh;
  margin-left: 20rem;
  width: calc(100vw - 20rem);
  height: 100vh;
  overflow: auto;
  overscroll-behavior: contain;
}
.loading-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-container > div {
  width: 100%;
}
.stat {
  font-family: 'JetBrains Mono', monospace;
}
.green {
  color: #67d967;
}
.red {
  color: #cd5454;
}
.panel {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  text-align: left;
  transition: background-color 0.1s;
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.panel:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.stats-row {
  display: flex;
  justify-content: space-between;
}
.stats-row > .stat {
  font-weight: bold;
}
.stats-row>span:last-child {
  text-align: right;
}
.page {
  padding: 0 64px 0 64px;
  max-width: 1300px;
  margin: auto;
  text-align: left;
}
.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
.menu-button {
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .page {
    padding: 0 1rem 0 1rem;
  }
  .container {
    width: 100vw;
    margin-left: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin-top: -100vh;
    margin-top: calc(var(--vh, 1vh) * -100);
  }
  .mobile-top-bar {
    position: sticky;
    top: 0;
    display: block;
    color: #fff;
    text-align: left;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(18px);
    z-index: 1000;
  }
}
</style>
