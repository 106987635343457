<template>
  <div class="panel">
    <span class="name">{{service.aliases[0].toUpperCase()}}
      <span>{{((progress/service.cost) * 100).toFixed(2)}}%</span>
    </span>
    <p>{{generateDescription(service.effects)}}</p>
  </div>
</template>

<script>
export default {
  name: "ServiceDisplay",
  props: ['service', 'progress'],
  methods: {
    generateDescription(effects) {
      let desc = '';
      for (let effect in effects) {
        if (effect === 'HAPPINESS') {
          let isPositive = effects[effect] > 0;
          desc += (isPositive ? '+' : '') + effects[effect] + ' happiness';
        }
        else if (effect === 'FACILITY_LEVEL') {
          desc += '+' + effects[effect] + ' facility levels';
        }
        else if (effect === 'POLICY_ACCESS') {
          desc += 'unlocks regional legislation and policies';
        }
        else if (effect === 'LAND_PRICE_COEFF_DELTA') {
          desc += this.$utils.formatPercentage(effects[effect]) + ' land price';
        }
        else if (effect === 'RESEARCH_TIME') {
          let isPositive = effects[effect] > 0;
          desc += (isPositive ? '+' : '-') + this.$utils.formatTime(effects[effect]) + ' research time';
        }
        else {
          desc += effect + effects[effect];
        }
      }
      return desc;
    }
  }
}
</script>

<style scoped>
.name {
  font-size: 0.9rem;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
