<template>
  <div class="row">
    <div>{{ name }}</div>
    <div class="stat">
      <div>{{ price }}</div>
      <div :class="{'green': isPositive(), 'red': isNegative()}">{{ change }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetRow",
  props: ['name', 'price', 'change'],
  methods: {
    isPositive() {
      return this.change.startsWith('+');
    },
    isNegative() {
      return this.change.startsWith('-');
    }
  }
}
</script>

<style scoped>
.row {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  transition: background-color 0.1s;
  cursor: pointer;
}
.row:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.row:last-child {
  border: none;
}
.row > div:last-child {
  text-align: right;
}
#graph {
  position: absolute;
}
</style>
