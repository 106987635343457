<template>
  <div>
    <div class="page" v-if="assetData && marketData">
      <div class="main">
        <img :src="assetData.emojiUrl">
        <div class="asset-name">{{ assetData.aliases[0] }}</div>
        <div class="asset-tag">{{ assetData.tag }}</div>
        <div class="asset-description">{{ assetData.description }}</div>
        <asset-graph id="graph" ref="graph" :price-history="marketData.priceHistory"></asset-graph>
        <div id="mobile-hovered-info">
          <h4 v-if="getGraph()">{{ getGraph().getTimeDisplay().toUpperCase() }}</h4>
          <div class="panel" v-if="getGraph()">
            <asset-hovered-info :market-data="marketData" :hovered-index="getGraph().hoveredIndex"></asset-hovered-info>
          </div>
        </div>
        <order-book :market-data="marketData"></order-book>
      </div>
      <div class="side-bar">
        <div id="desktop-hovered-info">
          <h4 v-if="getGraph()">{{ getGraph().getTimeDisplay().toUpperCase() }}</h4>
          <div class="panel" v-if="getGraph()">
            <asset-hovered-info :market-data="marketData" :hovered-index="getGraph().hoveredIndex"></asset-hovered-info>
          </div>
        </div>
        <h4>INFO</h4>
        <div class="panel">
          <asset-info :market-data="marketData" :asset-data="assetData"></asset-info>
        </div>
        <h4>BIDS</h4>
        <div class="panel">
          <asset-orders :orders="marketData.buyOffers" :asc="false"></asset-orders>
        </div>
        <h4>ASKS</h4>
        <div class="panel">
          <asset-orders :orders="marketData.sellOffers" :asc="true"></asset-orders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetInfo from "@/components/market/asset/AssetInfo";
import AssetOrders from "@/components/market/asset/AssetOrders";
import AssetGraph from "@/components/market/AssetGraph";
import AssetHoveredInfo from "@/components/market/asset/AssetHoveredInfo";
import OrderBook from "@/components/market/asset/OrderBook";
export default {
  name: "AssetPage",
  components: {OrderBook, AssetHoveredInfo, AssetGraph, AssetOrders, AssetInfo},
  data() {
    return {
      assetData: false,
      marketData: false
    }
  },
  methods: {
    init() {
      this.assetData = this.$parent.assetsData[this.$router.currentRoute.params.asset_id];
      this.$http.get(`https://ic-hg-service.teemaw.dev/market/asset/${this.assetData.id}`)
          .then((res) => {
            this.marketData = res.data;
          });
    },
    retry() {
      if (!this.$router.currentRoute.params) {
        setTimeout(this.retry(), 100);
      }
      else {
        this.init();
      }
    },
    getGraph() {
      return this.$refs.graph;
    }
  },
  mounted() {
    this.retry();
  }
}
</script>

<style scoped>
.page {
  display: flex;
  justify-content: space-between;
}
.panel {
  height: auto;
}
.asset-name {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0.5rem 0;
}
.asset-tag {
  color: rgba(255, 255, 255, 0.4);
  font-weight: lighter;
  margin: 0.5rem 0;
}
.asset-description {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}
.main {
  flex-grow: 1;
  margin-right: 1rem;
  margin-top: 1rem;
}
.side-bar {
  min-width: 20rem;
}
#mobile-hovered-info {
  display: none;
}
.page {
  padding-bottom: 1rem;
}
@media only screen and (max-width: 1100px) {
  .page {
    flex-direction: column;
  }
  .main {
    margin-right: 0;
  }
  .side-bar {
    width: auto;
  }
  #mobile-hovered-info {
    display: initial;
  }
  #desktop-hovered-info {
    display: none;
  }
}
</style>
