<template>
  <div>
    <div class="stats-row"
         v-for="([price, amount], i) in asc ? Object.entries(orders) : Object.entries(orders).reverse()"
         :key="i">
      <span>{{ formatCapital(price) }}</span>
      <span class="stat">{{ formatAmount(amount) }}</span>
    </div>
    <div class="empty" v-if="Object.keys(orders).length === 0">
      NO ORDERS
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetOrders",
  props: ['orders', 'asc'],
  methods: {
    formatCapital(capital) {
      return (capital / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },
    formatAmount(amount) {
      return amount.toLocaleString();
    },
  }
}
</script>

<style scoped>
.empty {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}
</style>
