<template>
  <div class="menu">
    <div class="wrapper">
      <div class="top-group">
        <div class="guild-name">IdleCorp</div>
        <div class="button-container">
          <div class="button" @click="$router.push('/me')" :class="$route.path === '/me' ? 'active' : ''" v-if="corporation">Profile</div>
          <div class="button" @click="$router.push('/market')" :class="$route.path === '/market' ? 'active' : ''">Market</div>
          <div class="sub button" @click="$router.push('/recent')" :class="$route.path === '/recent' ? 'active' : ''">Recent</div>
          <!--<div class="sub button" v-if="corporation">My Offers</div>
          <div class="button" v-if="corporation">Season Pass</div>
          <div class="sub button" v-if="corporation">Challenges<span class="ping">!</span></div>
          <div class="button" v-if="corporation">Reincorporate</div>-->
          <div class="button" @click="$router.push('/premium')" :class="$route.path === '/premium' ? 'active' : ''"><span class="gold gradient-text">Premium</span></div>
          <div class="button" v-if="!corporation" @click="$parent.signIn();">Sign in</div>
        </div>
        <div class="guild-name" v-if="guild">{{ guild.name }}</div>
        <div class="stat" v-if="guild">{{ $utils.encodeGuildId(region.guildId) }}</div>
        <div class="button-container" v-if="guild">
          <div class="button" @click="$router.push(`/region/${region.guildId}`)" :class="$route.name === 'region' ? 'active' : ''">Region</div>
          <!--<div class="button" @click="$router.push(`/region/${region.guildId}/facilities`)" :class="$route.path.endsWith('/facilities') ? 'active' : ''">Facilities</div>
          <div class="button" @click="$router.push(`/region/${region.guildId}/assets`)" :class="$route.path.endsWith('/assets') ? 'active' : ''">Assets</div>
          <div class="button" @click="$router.push(`/region/${region.guildId}/technologies`)" :class="$route.path.endsWith('/technologies') ? 'active' : ''">Technologies</div>
          <div class="button">Retail</div>
          <div class="button">Logistics</div>
          <div class="button">Research<span class="ping">!</span></div>
          <div class="button">Legislation</div>-->
        </div>
      </div>
      <div class="stat-group">
        <div v-if="corporation">
          <div class="stat">{{ $utils.formatCapital(corporation.capital) }}</div>
          <div>Tokens: <span class="stat">{{ $utils.formatAmount(corporation.riTokens) }}</span></div>
          <div>Grat. Points: <span class="stat">{{ $utils.formatAmount(corporation.votePoints) }}</span></div>
        </div>
        <div v-if="!corporation" @click="$parent.signIn();">
          <b>Sign in with Discord</b> to manage your corporation.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerMenu",
  props: ['corporation', 'region', 'guild']
}
</script>

<style scoped>
.menu {
  width: 15rem;
  background-color: rgba(255, 255, 255, 0.0168);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  text-align: left;
}
.wrapper {
}
.guild-name {
  font-weight: bold;
  margin-bottom: 0.5rem;
  opacity: 0.6;
}
.button-container {
  margin: 1rem 0;
  display: grid;
}
.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.1s;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 4px;
}
.button:hover, .button.active {
  background-color: rgba(0, 0, 0, 0.5);
}
.header {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.3);
}
.sub.button {
  padding-left: 2rem;
}
.ping {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: #faf236;
  display: inline-block;
  margin-left: 0.5rem;
  line-height: 1rem;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.gold {
  background-image: linear-gradient(45deg, #5F3E26, #f3ec78, #5F3E26);
}
.gradient-text {
  background-position-x: 100%;
  background-size: 200%;
  background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  transition: background-position 0.4s;
}
.gradient-text:hover {
  background-position-x: 0;
}
.top-group {
  height: calc(100vh - 9.6rem);
  height: calc(calc(var(--vh, 1vh) * 100) - 9.6rem);
  padding: 1.5rem;
}
.stat-group {
  position: absolute;
  bottom: 0;
  height: 3.6rem;
  line-height: 1.2rem;
  padding: 1.5rem;
  width: calc(100% - 3rem);
}

@media screen and (max-height: 815px) {
  .stat-group {
    box-shadow: 0 -20px 20px -15px #000;
  }
  .top-group {
    overflow-y: auto;
  }
}
</style>
