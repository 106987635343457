<template>
  <div class="left-nav" :class="openMobileMenu ? 'mm-open' : ''">
    <div class="top-group">
      <span class="brand" @click="() => {if ($route.path !== '/') $router.push('/')}">
        <img src="../assets/icon_transparent.png" alt="IdleCorp icon">
      </span>
      <div class="separator"></div>
      <div v-for="region in Object.values(regions)" :key="region.guildId">
        <div class="region"
             v-if="region.guildId > 0 && typeof guilds[region.guildId] !== 'undefined'"
             @click="selectRegion(region); $router.push(`/region/${region.guildId}`);"
             :class="region.guildId === selectedRegion.guildId ? 'active' : ''"
        >
          <img class="region-icon" :src="`https://cdn.discordapp.com/icons/${region.guildId}/${guilds[region.guildId].icon}.png`">
          <div class="tooltip">
            <div class="name">{{ guilds[region.guildId].name }}</div>
            <div class="stat" style="font-size: 0.8rem;">{{ $utils.encodeGuildId(region.guildId) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-group">
      <context-menu left="100%" bottom="0" :active="showUserContextMenu">
        <div v-if="isSignedIn()">
          <div class="header item">{{me.username}}<span class="stat">#{{me.discriminator}}</span></div>
          <div class="button item">Sign out</div>
        </div>
      </context-menu>
      <span @click="showUserContextMenu = !showUserContextMenu;">
        <img class="avatar" :src="`https://cdn.discordapp.com/avatars/${me.id}/${me.avatar}.png`" v-if="isSignedIn()">
      </span>
    </div>
    <InnerMenu class="inner-menu"
               :corporation="corporation"
               :guild="selectedRegion ? guilds[selectedRegion.guildId] : false"
               :region="selectedRegion"
               @click.native="$parent.openMobileMenu = false;">
    </InnerMenu>
  </div>
</template>

<script>
import InnerMenu from "@/components/InnerMenu";
import ContextMenu from "@/components/ContextMenu";
export default {
  name: "LeftNav",
  components: {ContextMenu, InnerMenu},
  props: ['me', 'corporation', 'regions', 'guilds', 'openMobileMenu'],
  data() {
    return {
      openMeDropdown: false,
      selectedRegion: false,
      showUserContextMenu: false,
    }
  },
  methods: {
    goBack() {
      if (window.history.length > 0) {
        this.$router.back();
      }
      else {
        this.$router.push('/');
      }
    },
    isSignedIn() {
      return window.$discord.hasMe();
    },
    signOut() {
      window.$discord.signOut();
    },
    signIn() {
      window.$discord.signIn();
    },
    selectRegion(region) {
      this.selectedRegion = region;
      this.$router.replace({params: {region_id: region.guildId}});
    }
  },
  created() {
    if (Object.values(this.regions).length > 0) {
      if (Object.values(this.regions)[0].guildId === '-1') {
        this.selectRegion(Object.values(this.regions)[1]);
      }
      else {
        this.selectRegion(Object.values(this.regions)[0]);
      }
    }
  }
}
</script>

<style scoped>
.left-nav {
  top: 0;
  z-index: 10000;
  position: sticky;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.6);
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.top-group {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
}
.bottom-group {
  margin-bottom: 1.5rem;
  width: 5rem;
  position: relative;
}
.inner-menu {
  position: absolute;
  left: 5rem;
  top: 0;
}
.brand {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.brand > img {
  height: 2rem;
}
.separator {
  width: 2rem;
  height: 0.15rem;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 1.5rem 0 0.75rem 0;
  border-radius: 4px;
}
.region-icon {
  width: 3rem;
  border-radius: 1.5rem;
}
.region {
  width: 5rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  position: relative;
}
.region::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 0;
  top: 1.5rem;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  opacity: 0;
  transition: height 0.1s, top 0.1s, opacity 0.1s;
}
.region:hover::before {
  height: 1rem;
  top: 1rem;
  left: 0;
  opacity: 1;
}
.region>.tooltip {
  position: absolute;
  left: 5rem;
  max-width: 10rem;
  text-align: left;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  text-overflow: ellipsis;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 1;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.1s, transform 0.1s;
  pointer-events: none;
}
.region:hover>.tooltip {
  transform: scale(1);
  opacity: 1;
}
.region>.tooltip>.name {
  font-weight: bold;
}
.active.region::before, .active.region:hover::before {
  height: 2.5rem;
  top: 0.25rem;
  left: 0;
  opacity: 1;
}
.avatar {
  width: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
}
@media only screen and (max-width: 1300px) {
  .left-nav {
    margin-left: -20rem;
    transition: margin-left 0.1s;
  }
  .left-nav.mm-open {
    margin-left: 0;
    backdrop-filter: blur(18px);
  }
}
@media only screen and (max-height: 815px) {
}
@media only screen and (max-width: 1100px) {
}
</style>
